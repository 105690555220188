@font-face {
    font-family: 'NeueHaasGroteskText Pro';
    src: url('NHaasGroteskTXPro-55Rg.woff2') format('woff2'),
        url('NHaasGroteskTXPro-55Rg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasGroteskText Pro';
    src: url('NHaasGroteskTXPro-56It.woff2') format('woff2'),
        url('NHaasGroteskTXPro-56It.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasGroteskText Pro';
    src: url('NHaasGroteskTXPro-75Bd.woff2') format('woff2'),
        url('NHaasGroteskTXPro-75Bd.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasGroteskText Pro Md';
    src: url('NHaasGroteskTXPro-65Md.woff2') format('woff2'),
        url('NHaasGroteskTXPro-65Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasGroteskText Pro';
    src: url('NHaasGroteskTXPro-75Bd.woff2') format('woff2'),
        url('NHaasGroteskTXPro-75Bd.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasGroteskText Pro Md';
    src: url('NHaasGroteskTXPro-65Md.woff2') format('woff2'),
        url('NHaasGroteskTXPro-65Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasGroteskText Pro';
    src: url('NHaasGroteskTXPro-56It.woff2') format('woff2'),
        url('NHaasGroteskTXPro-56It.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasGroteskText Pro';
    src: url('NHaasGroteskTXPro-55Rg.woff2') format('woff2'),
        url('NHaasGroteskTXPro-55Rg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

